import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path: '/how-to-mint',
        name: 'how-to-mint',
        component: () => import(/* webpackChunkName: "how-to-mint" */ '../views/HowToMint.vue')
    },
    {
        path: '/rarity',
        name: 'rarity',
        component: () => import(/* webpackChunkName: "rarity" */ '../views/Rarity.vue')
    },
    {
        path: '/giveaway',
        name: 'giveaway',
        component: () => import(/* webpackChunkName: "giveaway" */ '../views/Giveaway.vue')
    },
    {
        path: '/*',
        redirect: 'home',
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
