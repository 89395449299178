
import {Component, Vue} from 'vue-property-decorator';
import TopBar from "@/components/TopBar.vue";
import Pulse from "@/components/Pulse.vue";
import Loading from "@/components/Loading.vue";

@Component({
    components: {Loading, TopBar, Pulse}
})
export default class App extends Vue {
    loading = true;
    innerloading = false;

    beforeMount() {
        setTimeout(() => {
            this.innerloading = false;
        }, 1250);
        setTimeout(() => {
            this.loading = false;
        }, 1500);
    }

    get race() {
        return this.$route.name === "race";
    }

    mounted() {
        this.$nextTick(() =>{
            this.innerloading = true;
        })
    }
}
