
import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
    name: "TopBar"
})
export default class TopBar extends Vue {
    get links():string[] {
        return Object.keys(this.$t('links'));
    }

    get isHome() {
        return this.$route?.name === "home";
    }

    isLink(link:string) {
        return ["how-to-mint", "race", "rarity", "giveaway"].includes(link);
    }

    scrollTo(link: string) {
        const duration:number = 1000;

        if (link) {
            const layer: Element | null = document.body.querySelector('#' + link);

            if (layer) {
                this.$scrollTo(layer, duration);
            }
        } else {
            this.$scrollTo(document.body, duration);
        }
    }

    async goTo(link: string) {
        if (this.isLink(link)) {
            await this.$router.push({name: link});
            return;
        } else {
            if (link === "marketplace") {
                window.open("https://www.magiceden.io/marketplace/guinea_pigs_club", "_blank")
                return;
            }
            if (link === "whitepaper") {
                window.open("https://whitepaper.guineapigsclub.io", "_blank")
                return;
            }
            if (link === "gpcity") {
                window.open("https://gpcity.guineapigsclub.io", "_blank")
                return;
            }
            if (this.isLink(this.$route.name || '')) {
                await this.$router.push({name: 'home'});
                setTimeout(() => {
                    this.scrollTo(link);
                }, 500);
            } else {
                this.scrollTo(link);
            }
        }
    }
}
