import Vue from 'vue'
import App from './App.vue'
import router from './router'

import transitions from "vue2-transitions";
import scrollTo from "vue-scrollto";
import i18n from './i18n'

import VueGtag from "vue-gtag";
import VueMq from 'vue-mq'

Vue.use(VueMq, {
    breakpoints: { // default breakpoints - customize this
        mobile: 768,
        tablet: 1023,
        desktop: 1350,
        wide: Infinity,
    },
    defaultBreakpoint: 'sm' // customize this for SSR
})
Vue.use(transitions);
Vue.use(scrollTo);

if(process.env.VUE_APP_ENV === "production") {
    Vue.use(VueGtag, {
        config: {id: process.env.VUE_APP_ANALYTICS_ID}
    }, router);
}

Vue.config.productionTip = false

Vue.prototype.$assetsPrefix = process.env.VUE_APP_ASSETS_PREFIX || '';
Vue.prototype.$raceOutDuration = 3000;

new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')
